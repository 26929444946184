<template>
  <form>
    <div class="d-flex justify-content-center">
      <h4>Dados da Criança</h4>
    </div>
    <hr class="mb-4" />
    <b-row v-for="(schema, idx) in schemas" :key="`client_info${idx}`">
      <form-input-simple
        v-for="(formField, field_idx) in schema"
        v-model="model[formField.model]"
        :key="field_idx"
        :formField="formField"
        @input="$emit('input', model)"
        :errors="errors"
      />
    </b-row>
    <b-row
      v-for="(schema, idx) in schema_home_address"
      :key="`client_address${idx}`"
    >
      <form-input-simple
        v-for="(formField, field_idx) in schema"
        v-model="model.home_address[formField.model]"
        :key="field_idx"
        :formField="formField"
        @input="$emit('input', model)"
        :errors="addressErrors"
      />
    </b-row>
  </form>
</template>

<script>
import axios from "axios";
export default {
  props: ["value"],
  data() {
    return {
      errors: [],
      addressErrors: [],
      model: {
        name: "",
        cpf: "",
        rg: "",
        birthday: "",
        nationality: "Brasileiro",
        home_address: {
          zip_code: "",
          address: "",
          number: "",
          complement: "",
          district: "",
          state: "",
          city: "",
        },

        email: "",
        cell_phone: "",
      },
      temp: "",
      schema_home_address: [
        [
          {
            label: "CEP",
            model: "zip_code",
            size: "3",
            type: "number",
            mask: ["##.###-###"],
            required: true,
          },
          {
            label: "Endereço",
            model: "address",
            size: "6",
            type: "text",
            required: true,
          },
          {
            label: "Numero",
            model: "number",
            size: "3",
            type: "number",
            mask: ["######################"],
            required: true,
          },
        ],
        [
          {
            label: "Complemento",
            model: "complement",
            size: "3",
            type: "text",
          },
          {
            label: "Bairro",
            model: "district",
            size: "3",
            type: "text",
            required: true,
          },
          {
            label: "Cidade",
            model: "city",
            size: "3",
            type: "text",
            required: true,
          },
          {
            label: "Estado",
            model: "state",
            size: "3",
            type: "select",
            selectOptions: this.$store.state.reEnrolls.estados,
            required: true,
          },
        ],
      ],
      schemas: [
        [
          {
            label: "Nome",
            model: "name",
            size: "6",
            type: "text",
            required: true,
          },
          {
            label: "CPF",
            model: "cpf",
            size: "3",
            type: "number",
            mask: ["###.###.###-##"],
            required: true,
          },
          { label: "RG", model: "rg", size: "3", type: "text" },
        ],
        [
          {
            label: "Data de Nascimento",
            model: "birthday",
            size: "6",
            type: "birth",
            required: true,
            masked: true,
            mask: ["##-##-####"],
          },
          {
            label: "Nacionalidade",
            model: "nationality",
            size: "6",
            type: "text",
            required: true,
          },
        ],
        [
          {
            label: "Email",
            model: "email",
            size: "9",
            type: "text",
          },
          {
            label: "Celular",
            model: "cell_phone",
            size: "3",
            type: "number",
            mask: ["(##) # ####-####"],
          },
        ],
      ],
    };
  },
  components: {
    FormInputSimple: () => import("@/components/FormInputSimple"),
  },
  watch: {
    "model.home_address.zip_code": {
      handler(val) {
        val = val.replace(/[\. ,:-]+/g, "");
        this.get_zip_code(val, "home");
      },
    },
  },
  methods: {
    get_zip_code(value, type) {
      if (String(value).length == 8) {
        axios.get(`https://viacep.com.br/ws/${value}/json/`).then((res) => {
          console.log(res);
          this.model.home_address.address = res.data.logradouro;
          this.model.home_address.district = res.data.bairro;
          this.model.home_address.state = res.data.uf;
          this.model.home_address.city = res.data.localidade;
        });
      }
    },
    checkRequired() {
      console.log("checando");
      this.errors = [];
      this.addressErrors = [];
      const required_model = _.filter(_.flatten(this.schemas), (required) => {
        return required.required == true;
      }).map((r) => r.model);

      const required_address_model = _.filter(
        _.flatten(this.schema_home_address),
        (required) => {
          return required.required == true;
        }
      ).map((r) => r.model);

      _.forEach(required_model, (required) => {
        if (this.model[required] == "") {
          this.errors.push(required);
        }
      });
      _.forEach(required_address_model, (required) => {
        if (this.model.home_address[required] == "") {
          this.addressErrors.push(required);
        }
      });

      if (this.errors.length > 0 || this.addressErrors.length > 0) {
        return false;
      } else {
        if (this.validaCpf(this.model.cpf)) {
          return true;
        } else {
          this.makeToast("Número de CPF inválido");
          console.log("cpf invalido");
          return false;
        }
      }
    },
    getModel() {
      return this.model;
    },
  },
};
</script>

<style>
</style>